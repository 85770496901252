@CHARSET 'UTF-8' {}

@import 'variables';
@import 'mixins';

@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,700&subset=latin-ext');



[class^="fi-"]::before,
[class*=" fi-"]::before {
    margin-right: 0;
    margin-left: 0; }

.icon a div {
    height: 30px;
    width: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }

.icon {
    margin-right: 7px;
    background: white;
    padding: 10px;
    border-bottom: 4px solid black; }

.lastIcon {
    border-bottom: 0px; }

.tripIcon {
    background: url('../images/icons/icon_tripadvisor.svg'); }

.facebookIcon {
    background: url('../images/icons/icon_facebook.svg'); }

.smeIcon {
    background: url('../images/icons/icon_restauracie_sme.png'); }

.rightIcons {
    position: fixed;
    top: 140px;
    z-index: 100;
    right: 0px; }

.iconBlue {
    background:#3C5A99 {} }

.iconRed {
    background: #990003; }
.iconGreen {
    background: #20B36C; }

@media screen and (max-width:1230px) {
    .icon a div {
        height: 25px;
        width: 25px; }
    .rightIcons {
        margin-top: 20px;
        margin-bottom: 20px;
        display: none; }
    .icon {
        border-bottom: 0px;
        width: auto;
        display: inline-block;
        margin-right: 0px; }
    .iconBlue {
        display: inline-block;
        width: auto;
        background: transparent; }
    .iconGreen {
        width: auto;
        display: inline-block;
        background: transparent; }
    .iconRed {
        display: inline-block;
        width: auto;
        background: transparent; } }
@media screen and (min-width: 1231px) {
    .menuIcons {
        display: none; } }

#filedrag label {
	word-break: break-all; }

p {
	word-break: keep-all; }

a {
	color: $tertiaryColor;
	text-decoration: none;
	&:hover {
		color: lighten($primaryColor, 20);
		@include transition(); }
	img {
		border: none; } }

ul li.selected {
	a {
		color: #20B36C !important;
		&:hover {
			text-decoration: none !important; } } }
ul, ol {
	margin: 0px !important; }
nav {
	ul {
		list-style: none; } }

body {
		.mediaIcons {
			margin-top: 10px;
			span {
				font-size: 2em; } }
		.navbar-nav>li>a.logoLink {
			padding: 0px !important;
			margin-top: -15px !important;

			&:hover {
				border: none !important;
				background: transparent !important; } }

		font-family: $body-font-family;
		.row {
			margin-right: 0px !important;
			margin-left: 0px !important;
			max-width: none; }
		.introBackground {
			height: 100%;
			width: 100%;
			background: linear-gradient(to bottom, rgba(0,0, 0, .7), rgba(0, 0, 0, .7)), url('../images/pic1.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center; }
		.intro {
			background: transparent;
			width: 100%;
			text-align: center;
			text-transform: uppercase;
			width: 100%;
			h1, h2 {
				color: white;
				text-align: center; }
			h1 {
				margin-top: 0px;
				font-weight: 900;
				font-size: 1.8em;
				letter-spacing: 0.5px; }
			h2 {
				font-size: 1.3em;
				margin-top: 0px;
				font-weight: 300; }
			.indexButton {
				margin-top: 40px;
				display: block;
				margin-left: auto;
				margin-right: auto;
				background: #20B36C;
				padding: 17px 65px;
				color: white;
				font-weight: 600;
				text-transform: uppercase;
				font-size: 1.3em;
				letter-spacing: 0.5px;
				transition: background 0.5s;
				&:hover {
					background: white;
					color: #20B36C; } } } }

.navbar {
	margin-bottom: 0px !important; }
.navbar .navbar-collapse {
	text-align: center; }

li > a, a {
	transition: color 0.5s ease !important; }

body .navbar-nav>li>a.logoLink {
	border-bottom: none; }

.navbar-nav>li>a {
	color: black !important;
	font-weight: 600;
	text-transform: uppercase;
	font-size: 1em;
	margin-top: 20px !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	&:hover, &:active {
		color: #20B36C !important;
		border-top: 2px solid #20B36C;
		border-bottom: 2px solid #20B36C;
		background: transparent !important; }
	&:visited, &:link {
		text-decoration: none !important;
		background: transparent !important;
		outline-width: 0px !important; } }
.modalSubmit {
	border-radius: 10px !important;
	border: 2px solid $primaryColor;
	color: white;
	background: $primaryColor;
	margin-top: 0px;
	margin-bottom: 20px;
	display: block;
	margin-left: auto !important;
	margin-right: auto !important;
	transition: color .5s ease;
	text-transform: uppercase;
	padding: 10px 20px;
	font-weight: bold;
	&:visited, &:active, &:link {
		text-decoration: none !important;
		outline-width: 0px;
		color: white; }
	&:visited, &:active {
		color: white; }
	&:hover {
		background: $primaryColor;
		color: black !important;
		text-decoration: none !important; } }

.fixedWidthSubmit {
	width: 200px !important; }
.invertedSubmitButton {
	background: white !important;
	&:hover {
		color: black; } }

.navbar-nav>li.active > a {
	color: #20B36C !important;
	border-top: 2px solid #20B36C;
	border-bottom: 2px solid #20B36C;
	background: transparent; }

.menu {
	background: white;
	min-height: 100px;
	.glyphicon-menu-hamburger {
		margin-left: 5px; }
	.navbar-header p {
		font-weight: 600; }
	.toggleButton {
		position: absolute;
		top: 25px;
		right: 20px; }
	.minLogo {
		display: none; }
	@media screen and (max-width: $collapsing_width) {
			.reservationButton {
				margin-bottom: 10px; }
			.navbar nav>li>a {
				padding-top: 5px !important;
				padding-bottom: 5px !important; }
			.nav>li.first {
				margin-top: 100px; }
			.logoImage {
				width: 60px !important;
				height: 60px !important; }
			.logoImageLi {
				display: none; }
			.minLogo {
				position: absolute;
				top: 0;
				left: 0px;
				right: 0px;
				display: block;
				text-align: center; }
			.minLogo img {
				z-index: 100;
				position: relative;
				margin-top: 25px;
				margin-right: auto;
				margin-left: auto;
				width: 60px !important; }
			.navbar-header {
				float: none; }
			.navbar-toggle {
				display: block; }
			.navbar-collapse {
				border-top: 1px solid transparent; }
			.navbar-collapse.collapse {
				display: none!important; }
			.navbar-nav {
				float: none!important;
				margin: 7.5px -15px; }
			.navbar-nav>li {
					float: none; }
			.navbar-text {
				float: none;
				margin: 15px 0; }
			.navbar-collapse.collapse.in {
				display: block!important; }
			.collapsing {
				overflow-x: hidden !important; }
			.navbar-nav>li>a {
				margin-top: 10px !important; } }
	@media screen and (min-width: 1201px) {
		.logoImage {
			width: 218px;
			height: 218px; } }

	.language {
		position: absolute;
		z-index: 100;
		margin-top: 45px;
		margin-left: 30px;
		a {
			font-weight: 600;
			color: rgba(0,0,0,.3);
			&:hover {
				color: #20B36C; } }

		ul#lang li a {
			padding: 0px 5px; }

		ul#lang li > a {
			border-right: 1px solid rgba(0,0,0,.25); }
		ul#lang li:last-of-type > a {
			border-right: none; } }

	.navbar-center {
		display: inline-block;
		margin: 0 auto;
		float: none;
		height: 0px; }
	a {
		padding-top: 50px; }
	.reservationButton {
		display: block;
		background: #20B36C;
		padding: 12px 17px;
		border-radius: 8px;
		color: white;
		margin-top: 30px;
		margin-right: 20px;
		font-weight: 600;
		text-transform: uppercase;
		font-size: 0.9em;
		border: 2px solid #20B36C;
		letter-spacing: 0.5px {
			transition: background 0.5s; }
		&:hover {
			color: black;
			text-decoration: none; }
		&:visited, &:active, &:link {
			text-decoration: none !important;
			outline-width: 0px !important; } }
	.logoImage {
		z-index: 100;
		position: relative;
		width: 170px;
		height: 170px;
		margin-left: 30px;
		margin-right: 30px; } }

ul#lang {
	list-style: none;
	margin: 0;
	*zoom: 1;
	li {
		float: left;
		visibility: collapse;
		a {
			display: block;
			padding: 3px 10px;
			visibility: visible; } }
	&:before,
	&:after {
		content: ' ';
		display: table; }
	&:after {
		clear: both; }
	&.flag {
		a {
			width: 2rem;
			height: 1.5rem;
			background-size: contain;
			background-position: 50%;
			background-repeat: no-repeat; } }
	&.code {
		a {
			&:before {
				content: attr(data-code);
				text-transform: uppercase; } } }
	&.name {
		a {
			&:before {
				content: attr(title); } } } }



.relative {
	position: relative; }

.align-center {
	text-align: center; }
.middle {
	@include verticalAlign(); }


.bold {
	font-weight: bold; }
.uppercase {
	text-transform: uppercase; }


/* line break after element */
.br {
	&:after {
		content: '\A';
		white-space: pre; } }

/* clear-fix */
.cf {
	*zoom: 1;
	&:before,
	&:after {
		content: ' ';
		display: table; }
	&:after {
		clear: both; } }

@media screen and (max-width: 1024px) {
	.cf-medium {
		*zoom: 1;
		&:before,
		&:after {
			content: ' ';
			display: table; }
		&:after {
			clear: both; } } }
@media screen and (min-width: 1025px) {
	.small-0 {
		width: 0; } }




/* proportional height */
#testProportional {
	margin: 50px auto;
	width: 300px;
	background-color: green; }

.crust {
	position: relative;
	width: 100%;
	height: auto;
	overflow: hidden;
	&:before {
		content: '';
		display: block;
		padding-top: 100%; }
	.core {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		text-align: center;
		img,
		.content {
			max-width: 100%;
			max-height: 100%;
			height: auto;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto; }
		.content {
			width: 100%;
			height: 100%; } }
	&.cover .core img {
		/*width: 100%*/
		/*height: auto*/
		/*max-height: none*/
		min-width: 100%;
		min-height: 100%;
		height: auto;
		max-width: none; }
	&.ratio-2_1:before {
		padding-top: 50%; }
	&.ratio-4_3:before {
		padding-top: 75%; }
	&.ratio-16_9:before {
		padding-top: 56.25%; }
	&.ratio-9_4:before {
		padding-top: 44.44%; }
	&.ratio-5_4:before {
		padding-top: 80%; }
	&.ratio-1_2:before {
		padding-top: 200%; }
	&.ratio-4_5:before {
		padding-top: 125%; } }

/* icon before content */
a.icon-prev {
	&:hover {
		color: lighten($primaryColor, 20); } }
.icon-prev {
	position: relative;
	display: inline;
	margin-left: 2.25em;
	white-space: nowrap;
	&:before {
		position: absolute;
		margin-top: 0.15em;
		left: -1.20em;
		font-size: 1.10em;
		color: $quaternaryColor; }
	&:first-child {
		margin-left: 1.35em; } }





/* ADMIN: edit text on the page */
.admin-edit {
	position: relative;
	border: 1px dashed transparentize($warningColor, 0.75);
	background-color: transparentize(white, 0.95);
	overflow: hidden;
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		border: 1px solid transparentize(darken($warningColor, 30), 0.75);
		background-color: transparentize(black, 0.95);
		&:hover {
			display: none; } }
	&:hover {
		border: 1px solid darken($errorColor, 10);
		background-color: transparentize(white, 0.33);
		color: darken($errorColor, 20);
		cursor: url(../images/pencil-red-16.gif) 0 16, pointer; }
	&[data-variant="static_content"] {
		display: inherit;
		&:hover {
			cursor: url(../images/doc-edit-16.gif) 0 16, pointer; } } }
.menu-content-edit {
	@extend .admin-edit;
	display: inherit;
	&:hover {
		cursor: url(../images/doc-edit-16.gif) 0 16, pointer; } }


body {
	.actualities {
		position: absolute;
		bottom: 0px;
		width: 100%;
		background: rgba(0,0,0, .5);
		min-height: 100px;
		z-index: 101;
		.partActualities {
			text-align: justify;
			color: white;
			padding: 20px;
			border-top: 4px solid white;
			&:hover {}
			border-top: 4px solid $primaryColor; }
		.partActualities {
			padding: 20px 0px 40px 0px;
			h3 {
				font-size: 1.2em;
				text-transform: uppercase;
				font-weight: bold; } }
		.partActualities {
			padding: 20px;
			text-align: justify;
			color: white;
			transition: border-color 0.5s ease;
			padding: 20px 0px 40px 0px;
			border-top: 3px solid white;
			padding-left: 0px !important;
			padding-right: 0px !important; }
		.partActualities h3 {
			font-size: 1.2em;
			text-transform: uppercase;
			font-weight: bold; }
		.partActualities:hover {
			border-top: 3px solid $primaryColor; } }
	> footer {
		@media screen and (max-width: $collapsing_width) {
			#reservationButton {
				width: 200px !important;
				display: block !important;
				margin-right: auto !important;
				margin-left: auto !important; }
			p.copyrightContent {
				padding-top: 0px !important; } }
		position: absolute;
		bottom: 0px;
		z-index: 10000;
		width: 100%;
		padding: 5px;
		background-color: $secondaryColor;
		background-repeat: no-repeat;
		background-position: center top;
		background-size: cover;
		color: $tertiaryColor;
		p.copyrightContent {
			padding-top: 15px; }
		p {
			padding-top: 5px;
			margin-bottom: 5px;
			font-size: 1.1em;
			letter-spacing: 0.5px; }
		.col-sm-6 {
			padding-top: 10px; }
		a {
			color: inherit;
			&:hover {
				color: lighten($primaryColor, 20); } }
		section {
			&:first-child {
				background-color: $secondaryColor; } }
		nav {
			ul {
				list-style: none;
				color: $tertiaryColor;
				text-transform: uppercase;
				text-align: center;
				margin-top: 10px;
				a {
					color: inherit;
					background: none !important;
					padding: .7rem 2rem;
					display: block; }
				li {
					display: table-cell;
					float: left; }
				ul {
					display: none; } }
			ul:first-child {
				> li {
					> a {
						border-top: 6px solid transparent;
						background-color: transparent; }
					&.active,
					&:hover {
						> a {
							border-top: 6px solid $primaryColor; } } } } }

		.logo {
			margin-top: 45px; }
		h3 {
			margin-top: 75px;
			margin-left: 1.40em;
			color: $primaryColor;
			text-transform: uppercase; }
		.hr {
			hr {
				margin-top: 60px;
				border-color: #5e5e5e; } }
		.disclaimer {
			span {
				color: $tertiaryColor; }
			p {
				margin-top: 1rem; } }
		.company {
			color: $primaryColor; }
		.sixnet {
			font-weight: 600;
			color: $sixthColor;
			&:hover {
				color: white; } }
		.siete {
			font-size: 45px;
			a {
				color: $whiteColor;
				&:hover {
					color: $whiteColor; } } }
		.icon-prev {
			&:before {
				color: $primaryColor; } }
		.operation {
			a {
				color: inherit; }
			span {
				&:first-child {} } }
		/*font-weight: bold*/
		.bill-info {
			span {
				text-transform: uppercase; } }

		[role="copyright"] {
			font-size: 0.75em;
			text-align: center; } } }

.modal-header .close {
	position: absolute;
	top: 20px;
	right: 20px;
	font-size: 2.5em; }

.modal-content {
	background-color: rgba(255,255,255,0.9) !important;
	border-radius: 0px !important; }

.modal-dialog {
	margin-top: 40px; }

#exampleModalLongTitle {
	letter-spacing: 1px;
	font-size: 2em;
	font-weight: bold;
	color: #20B36C;
	margin-top: 20px;
	text-transform: uppercase;
	text-align: center; }

.modal {
	text-align: center;
	padding-left: 0px !important;
	h3 {
		text-align: center;
		font-size: 1.2em; }
	h4 {
		font-weight: bold;
		font-size: 1.7em;
		color: $primaryColor;
		padding-bottom: 20px; }
	.section > div {
		padding: 20px; } }

.modal {
	.vertical-alignment-helper {
		display: table;
		height: 100%;
		width: 100%;
		pointer-events: none; }
	.vertical-align-center {
		display: table-cell;
		vertical-align: middle;
		pointer-events: none; }
	.modal-content {
		width: inherit;
		height: inherit;
		margin: 0 auto !important;
		pointer-events: all; }
	.modalSection {
		h4 {
			word-spacing: 999999px; } }
	& > div:nth-child(1) {
		border-right: 1px solid rgba(0,0,0,.1); }

	label {
		width: 100%;
		font-size: 0.9em;
		font-weight: bold; }
	.modal-footer {
		text-align: center; } }
.inputText {
	min-height: 50px !important;
	color: #000;
	padding: 12px 10px;
	font-size: 1.1em;
	font-weight: 400;
	border-radius: 0px !important;
	border: 1.5px solid transparent;
	&:focus {
		border: 1.5px solid #20B36C !important; } }
.inputArea {
	min-height: 40px;
	height: 60px;
	border-radius: 0px !important;
	padding: 10px 10px;
	&:focus {
		border: 1.5px solid #20B36C !important; } }


#filedrag {
	display: block;
	font-weight: bold;
	text-align: center;
	padding: 1em 0;
	margin: 5px 0;
	color: #555;
	border: 2px dashed green;
	border-radius: 7px;
	cursor: default;
	margin-bottom: 20px;
	label {
		word-break: keep-all; } }

#filedrag.hover {
	color: #f00;
	border-color: #f00;
	border-style: solid;
	box-shadow: inset 0 3px 4px #888; }

#filedrag #file {
	display: none; }

#reservationModal, #careerModal {
	z-index: 10000; }
#reservationModal {
	form {
		h2.header2 {
			text-transform: uppercase;
			font-weight: bold;
			margin-top: 0;
			font-size: 1.5em; }
		.numberOfGuestsSpan {
			float: left;
			font-size: 1.1em;
			display: inline-block;
			margin-top: 15px; }

		.numberOfGuests.inputText {
			display: inline-block;
			width: 50%;
			margin-right: 0px !important;
			float: right;
			margin-bottom: 0; }
		h2.headerDatepicker {
			text-transform: uppercase;
			font-weight: bold;
			margin-top: 80px;
			display: block;
			float: none;
			font-size: 1.5em; }
		h2.headerPoznamka {
			text-transform: uppercase;
			font-weight: bold;
			margin-top: 20px;
			display: block;
			float: none;
			font-size: 1.5em; } }

	.modal-content {
		max-width: 400px;
		.modal-header {
			padding-bottom: 0px; } } }



.reveal-overlay {
	z-index: 20000 !important; }

.reservationIcons {
	background: white !important;
	border-left: none; }

.reservationIcon {
	color: #20B36C;
	background: white;
	font-size: 1.4em; }

.datepicker {
	padding: 10px !important;
	font-family: Ubuntu;
	border-radius: 0px !important;
	min-height: 50px;
	margin-bottom: 0px !important;
	padding: 10px;
	font-size: 1.1em; }

.datepicker:focus {
	border: 1px solid #20B36C; }

.inputArea {
	min-height: 90px; }
.input-group-addon {
	border: none !important; }

.navbar {
	min-height: 95px !important;
	background: white !important;
	z-index: 100 !important; }

.cycle-slide, .cycle-slide-active {
	z-index: 5 !important; }
.cycle-slideshow {
	width: 100% !important;
	height: 100% !important; }
.cycle-slideshow li {
	width: 100% !important;
	height: 100% !important;
	z-index: 5 !important; }


@media screen and (max-width: $collapsing_width) {
	.reservationButton {
		width: 200px !important;
		display: block !important;
		margin-left: auto !important;
		margin-right: auto !important; } }

.menuHr {
	margin-top: 0px !important;
	border: 4px solid #20B36C;
	width: 90px;
	opacity: 0;
	transition: opacity .35s ease-in-out;
	-moz-transition: opacity .35s ease-in-out;
	-webkit-transition: opacity .35s ease-in-out; }

.navbar .navbar-center li {
	padding: 0px 5px !important; }


.navbar-nav>li.active>a {
	border: none !important;
	color: black !important; }

.navbar-nav>li>a:hover {
	border: none !important;
	color: black !important; }

.navbar-nav>li.active>hr {
	opacity: 1; }

.navbar-nav>li:hover>hr {
	opacity: 1; }

#filedrag {
	padding: 0px !important; }

#filedrag label {
	padding: 20px 20px;
	margin-bottom: 0px;
	border: none !important;
	background: transparent; }

//input[type="file"]
//display: none !important

.custom-file-upload {
	border: 1px solid #ccc;
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
	font-weight: bold; }

.cycle-slideshow li {
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover; }
.sliderImage {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover; }


.reveal {
	z-index: 200000; }

#infoModalHr {
	border: 3px solid #20B36C;
	width: 200px;
	border: 3px solid #20B36C;
	width: 200px; }

#messageModal {
	p, h2 {
		text-align: center; }
	h2 {
		text-transform: uppercase; } }

